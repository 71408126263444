import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RouterModule } from '@angular/router';
import { PowerDataComponent } from './power-data.component';
import { PowerTurbinesComponent, TurbogenPowerReserveComponent } from './components';
import { UiGraphModule } from 'src/modules/app/components/ui-graph/ui-graph.module';
import { CardErrorModule } from 'src/modules/app/components/card-error/card-error.module';

@NgModule({
  declarations: [PowerDataComponent, PowerTurbinesComponent, TurbogenPowerReserveComponent],
  imports: [
    CommonModule,
    UiGraphModule,
    NgxSkeletonLoaderModule,
    CardErrorModule,
    RouterModule
  ],
  exports: [PowerDataComponent, PowerTurbinesComponent, TurbogenPowerReserveComponent]
})
export class PowerDataModule { }
